import React from "react";
import IntersectionObserverComponent from "./scripts/IntersectionObserverComponent";
import CheckIcon from '@mui/icons-material/Check';

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            
           
            <img src="img/about.jpg" className="img-responsive" alt="" /> 
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
            <IntersectionObserverComponent animationClass='slideRight'>
              <h2>Σχετικα με εμας</h2>
              </IntersectionObserverComponent>
              <p style={{textAlign:'justify', textJustify:'auto'}} >{props.data ? props.data.paragraph : "loading..."}</p>
           
              <h3>Γιατί να μας επιλέξετε?</h3>
              <div className="list-style">
                <div className="col-lg-7 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>
                            <CheckIcon  style={{color: '#26a269'}} />
                            {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-5 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> 
                             <CheckIcon  style={{color: '#26a269'}} />
                              {d}</li>
                        ))
                      : "loading"}
                  </ul> 
                </div> 
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
