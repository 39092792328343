import React, { useState, useEffect } from 'react';

const IntersectionObserverComponent = ({ children, threshold = 0.5, animationClass }) => {
  const [animate, setAnimate] = useState(false);

  const sectionRef = React.useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setAnimate(true);
          } else {
            setAnimate(false);
          }
        });
      },
      { threshold }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [threshold]);

  return (
    <div className={animate ? animationClass : ''} ref={sectionRef}>
      {children}
    </div>
  );
};

export default IntersectionObserverComponent;
