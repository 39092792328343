import React from "react"
import IntersectionObserverComponent from "./scripts/IntersectionObserverComponent"
import { FaLightbulb } from 'react-icons/fa';
import { BiLineChart } from 'react-icons/bi';
import { FaCogs } from 'react-icons/fa';

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
        <IntersectionObserverComponent animationClass=" ">
          <h2>Οι Υπηρεσιες μας</h2>
          </IntersectionObserverComponent>
          <p>
          Η TrikkaLabs υποστηρίζει την έρευνα, από τη σύλληψη του έργου μέχρι την υλοποίηση και τα στάδια ανάπτυξης και επίδειξης πρωτοτύπων.
          </p>
        </div>
        <div className="row"  >
     
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <IntersectionObserverComponent animationClass="bounce"  maxBounces={2}> 
                  <div className="servicesIcons">
                  {d.icon === 'fa fa-lightbulb' && <FaLightbulb  />  }
                  {d.icon === 'fa fa-cogs' && <FaCogs  />  }
                  {d.icon === 'fa fa-chart-line' && <BiLineChart   />  }
                  </div> 
                 
                  </IntersectionObserverComponent>      
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p style={{textAlign:'justify', textJustify:'auto'}} >{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"} 
     
        </div>
      </div>
    </div>
  )
}
