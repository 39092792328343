import React from 'react';
import { Modal, Box, Typography, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function TestModal({ title, content, closeModal }) {
  return (
    <Modal open={true} onClose={closeModal} >
      <Box
        sx={{
          position: 'absolute',
          width: '60%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius:'10px'
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeModal}
          aria-label="close"
          sx={{ position: 'absolute', top: 8, right: 15 }}
        >
          <CloseIcon/>
            
        </IconButton>
        <Typography variant="h4" mb={2}>
          {title}
        </Typography>
        <Divider sx={{width:'100%'}} />
        <Typography>
                <h4>Problem/Opportunity</h4> 
                <h4>
                Solution
                </h4>
                <p>
                TEST
                </p> 
        </Typography>
      </Box>
    </Modal>
  );
}

export default TestModal;
